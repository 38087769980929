<div class="container-fluid p-0">
    <div class="row">
        <div class="col-12">
            <div class="login-card login-dark">
                <div>
                    <div>
                        <a class="logo" routerLink="/">
                            <img
                                class="img-fluid for-light"
                                style="width: 120px"
                                [src]="themeConfig?.logo?.driveId || 'assets/images/logo/logo.png'"
                                alt="looginpage"
                            />
<!--                            <img-->
<!--                                class="img-fluid for-dark"-->
<!--                                src="assets/images/logo/logo_dark.png"-->
<!--                                alt="looginpage"-->
<!--                            />-->
                        </a>
                    </div>
                    <div class="login-main">
                        <form class="theme-form" [formGroup]="loginForm">
                            <h4>Sign in to account</h4>
                            <p>Enter your email & password to login</p>
                            <div class="form-group">
                                <label class="col-form-label"
                                    >Email Address</label
                                >
                                <input
                                    class="form-control"
                                    type="email"
                                    required=""
                                    placeholder="app@yourmail.com"
                                    formControlName="email"
                                />
                                <div
                                    *ngIf="
                                        loginForm.controls.email.touched &&
                                        loginForm.controls.email.errors
                                            ?.required
                                    "
                                    class="text text-danger mt-1"
                                >
                                    Email is required
                                </div>
                                <div
                                    *ngIf="
                                        loginForm.controls.email.touched &&
                                        loginForm.controls.email.errors?.email
                                    "
                                    class="text text-danger mt-1"
                                >
                                    Invalid Email
                                </div>
                            </div>
                            <div class="form-group">
                                <!-- Password input field -->
                                <label class="col-form-label">Password</label>
                                <input
                                    class="form-control"
                                    [type]="show ? 'text' : 'password'"
                                    formControlName="password"
                                    required=""
                                    placeholder="*********"
                                />
                                <div
                                    class="show-hide"
                                    (click)="showPassword()"
                                    *ngIf="!show"
                                >
                                    <span class="show"></span>
                                </div>
                                <div
                                    class="show-hide"
                                    (click)="showPassword()"
                                    *ngIf="show"
                                >
                                    <span class="Hide"></span>
                                </div>
                                <div
                                    *ngIf="
                                        loginForm.controls.password.touched &&
                                        loginForm.controls.password.errors
                                            ?.required
                                    "
                                    class="text text-danger mt-1"
                                >
                                    Password is required
                                </div>
                            </div>
                            <div class="form-group mb-0">
                                <!-- Remember me checkbox and Forgot password link -->
                                <div class="checkbox p-0">
                                    <input id="checkbox1" type="checkbox" />
                                    <label class="text-muted" for="checkbox1"
                                        >Remember password</label
                                    >
                                </div>
                                <a
                                    class="link"
                                    routerLink="/auth/forgot-password"
                                    >Forgot password?</a
                                >
                                <!-- Sign in button -->
                                <div class="text-end mt-3">
                                    <button
                                        class="btn btn-primary d-block w-100"
                                        [disabled]="!loginForm.valid"
                                        (click)="getIpInfo()"
                                        type="submit"
                                    >
                                        <span>Sign in</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
