import {Injectable, OnDestroy} from "@angular/core";
import {BehaviorSubject, fromEvent, Subject} from "rxjs";
import {debounceTime, takeUntil} from "rxjs/operators";
import {Router} from "@angular/router";

// Menu
export interface Menu {
    headTitle1?: string;
    headTitle2?: string;
    path?: string;
    title?: string;
    icon?: string;
    type?: string;
    role?: string[];
    badgeType?: string;
    badgeValue?: string;
    active?: boolean;
    bookmark?: boolean;
    children?: Menu[];
}

@Injectable({
    providedIn: "root",
})
export class NavService implements OnDestroy {
    public screenWidth: BehaviorSubject<number> = new BehaviorSubject(
        window.innerWidth
    );
    // Search Box
    public search: boolean = false;
    // Language
    public language: boolean = false;
    // Mega Menu
    public megaMenu: boolean = false;
    public levelMenu: boolean = false;
    public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;
    // Collapse Sidebar
    public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;
    // For Horizontal Layout Mobile
    public horizontal: boolean = window.innerWidth < 991 ? false : true;
    // Full screen
    public fullScreen: boolean = false;
    MENUITEMS: Menu[] = [
        {
            title: "Dashboard",
            icon: "home",
            type: "link",
            badgeType: "light-primary",
            badgeValue: "2",
            active: true,
            path: "/simple-page/first-page",
            role: ["ADMIN", "SUPER", "MANAGER"],
            // children: [
            //   { path: "/simple-page/first-page", title: "Ecommerce", type: "link" },
            // ],
        },
        // { path: "/pay-in", icon: "search", title: "Pay In",  active: false, type: "link", bookmark: true },
        // { path: "/pay-in-statement", icon: "search", title: "Pay In Statement",  active: false, type: "link", bookmark: true },
        {
            icon: "bonus-kit",
            // //   type: "link",
            // //   badgeType: "light-primary",
            // //   badgeValue: "2",
            // //   active: true,
            // icon: "calendar",
            title: "Schedule",
            active: false,
            type: "sub",
            bookmark: true,
            role: ["ADMIN", "EMPLOYEE", "DRIVER", "SUPER", "MANAGER"],
            children: [
                {
                    path: "/booking",
                    icon: "users",
                    title: "Calender View",
                    active: false,
                    type: "link",
                },
                {
                    path: "/listView",
                    icon: "user",
                    title: "List View",
                    active: false,
                    type: "link",
                    bookmark: true,
                },
            ],
        },
        {
            path: "/cashflow",
            icon: "refresh-cw",
            title: "Cashflow",
            active: false,
            type: "link",
            bookmark: true,
            role: ["ADMIN", "SUPER", "MANAGER"],
        },
        {
            path: "/expenses",
            icon: "dollar-sign",
            title: "Expenses",
            active: false,
            type: "link",
            bookmark: true,
            role: ["ADMIN", "DRIVER", "SUPER", "MANAGER"],
        },
        {
            path: "/profit-loss",
            icon: "trending-up",
            title: "Profit/Loss",
            active: false,
            type: "link",
            bookmark: true,
            role: ["ADMIN", "SUPER", "MANAGER"],
        },
        {
            path: "/clients",
            icon: "users",
            title: "Clients",
            active: false,
            type: "link",
            bookmark: true,
            role: ["ADMIN", "SUPER", "MANAGER"],
        },
        {
            path: "/quotations",
            icon: "check-square",
            title: "quotations",
            active: false,
            type: "link",
            bookmark: true,
            role: ["ADMIN", "SUPER", "MANAGER"],
        },
        {
            path: "/invoice",
            icon: "check-square",
            title: "invoice",
            active: false,
            type: "link",
            bookmark: true,
            role: ["ADMIN", "SUPER", "MANAGER"],
        },

        {
            title: "Settings",
            icon: "icons",
            type: "sub",
            role: ["ADMIN", "SUPER", "MANAGER"],
            children: [
                {
                    path: "/clients/subscriptions",
                    icon: "check-square",
                    title: "Client Subscriptions",
                    active: false,
                    type: "link",
                },
                {
                    path: "/employees",
                    icon: "user",
                    title: "Employees",
                    active: false,
                    type: "link",
                    bookmark: true,
                },

                {
                    path: "/subscriptions",
                    icon: "check-square",
                    title: "Subscriptions",
                    active: false,
                    type: "link",
                    bookmark: true,
                },

                {
                    path: "/category",
                    icon: "folder",
                    title: "Category",
                    active: false,
                    type: "link",
                    bookmark: true,
                },
                {
                    path: "/attendance",
                    icon: "folder",
                    title: "Attendance",
                    active: false,
                    type: "link",
                    bookmark: true,
                },
                {
                    path: "/companyProfile",
                    icon: "folder",
                    title: "Company Profile",
                    active: false,
                    type: "link",
                    bookmark: true,
                },
            ],
        },
        // {
        //   path: "/reports",
        //   icon: "file-text",
        //   title: "Reports",
        //   active: false,
        //   type: "link",
        //   bookmark: true,
        //   role: ['ADMIN'],
        //
        // },
    ];
    // Array
    items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
    private unsubscriber: Subject<any> = new Subject();

    constructor(private router: Router) {
        this.setScreenWidth(window.innerWidth);
        fromEvent(window, "resize")
            .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
            .subscribe((evt: any) => {
                this.setScreenWidth(evt.target.innerWidth);
                if (evt.target.innerWidth < 991) {
                    this.collapseSidebar = true;
                    this.megaMenu = false;
                    this.levelMenu = false;
                }
                if (evt.target.innerWidth < 1199) {
                    this.megaMenuColapse = true;
                }
            });
        if (window.innerWidth < 991) {
            // Detect Route change sidebar close
            this.router.events.subscribe((event) => {
                this.collapseSidebar = true;
                this.megaMenu = false;
                this.levelMenu = false;
            });
        }
    }

    ngOnDestroy() {
        // this.unsubscriber.next();
        this.unsubscriber.complete();
    }

    private setScreenWidth(width: number): void {
        this.screenWidth.next(width);
    }
}
