import {Component, OnInit, ViewChild} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DataTableDirective} from "angular-datatables";
import {NgbDate, NgbDateStruct, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {HttpClient} from "@angular/common/http";
import {BackendService} from "../../shared/services/backend.service";
import Swal from "sweetalert2";
import {DateUtils} from "../../utils/date-utils";
import {CONFIG} from "../../../../config";
import {DatePipe} from "@angular/common";
import {Router} from "@angular/router";
import {UtilsService} from "src/app/shared/services/utils.service";
import {DataSharingService} from "src/app/shared/services/data-sharing.service";
import {jsPDF} from "jspdf";
import "jspdf-autotable";

@Component({
    selector: "app-list-view",
    templateUrl: "./list-view.component.html",
    styleUrl: "./list-view.component.scss",
})
export class ListViewComponent implements OnInit {
    form: FormGroup;
    previousFlag: string;
    @ViewChild(DataTableDirective)
    dataTableElement!: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    @ViewChild(DataTableDirective, {static: false})
    dtElement!: DataTableDirective;
    startDate: NgbDateStruct;
    endDate: any;
    formattedStartDate: any;
    formattedEndDate: any;
    bookings: any[] = [];
    displayMonths = 1;
    navigation = "select";
    outsideDays = "visible";
    showWeekNumbers = true; // Define the showWeekNumbers property here
    users: any[] = [];

    // Modal variables
    modalTitle: string = "Booking Title";
    modals: any = {
        updateBooking: "Update Booking",
        addBooking: "Add Booking",
    };
    eventDate: Date = new Date();
    tempBookingId: string = "";
    eventColumnChanged: boolean = false;
    updateCancelled: boolean = true;
    assignees: any[] = [];

    dropdownList = [];
    selectedItems = [];
    dropdownSettings = {};

    // Logged in user
    currentUserRole: string;

    // Subscriptions variables
    currentClientSubscriptions: any[] = [];
    selectedSubscription: any = null;

    // Multiple Clients Select
    selectedClients: any[];
    totals: any;

    // Booking loading state tracker
    bookingLoading: boolean = false;
    today: any;
    @ViewChild("content") content: any;
    @ViewChild("clientContent") clientContent: any;
    @ViewChild("employeeContent") employeeContent: any;
    clientData: any = {};
    employeeData: any = {};
    dataTableParam: any;
    clients: any[] = [];
    categories: any[] = [];
    events: any = [];
    // Calendar configuration variables
    viewDate: Date;
    bookingDate: string;
    endTime: any;
    startTime: any;
    // Open Modal For Employee
    selectedBooking: any;

    constructor(
        private modalService: NgbModal,
        private http: HttpClient,
        private backendService: BackendService,
        private fb: FormBuilder,
        private router: Router,
        private datePipe: DatePipe,
        private utils: UtilsService,
        private data: DataSharingService
    ) {
        this.form = this.fb.group({
            bookingName: ["Cleaning Booking", Validators.required],
            clientId: ["", Validators.required],
            isOneTime: [true],
            subscriptionId: [null],
            startDate: ["", Validators.required],
            startTime: ["", Validators.required],
            endTime: ["", Validators.required],
            material: [false],
            materialCharges: [null],
            ratePerHour: [null, Validators.required],
            paymentStatus: ["DUE", Validators.required],
            description: ["XYZ", Validators.required],
            assigneeId: ["", Validators.required],
        });
    }

    onFlagChange(selectedValue: string, booking: any) {
        // Store the previous value if it hasn't been stored yet
        if (!this.previousFlag) {
            this.previousFlag = booking.flag;
        }

        // Check if the selected flag is "CANCELLED" or "COMPLETED"
        if (selectedValue === 'CANCELLED' || selectedValue === 'COMPLETED') {
            // Show confirmation modal
            Swal.fire({
                title: `Are you sure you want to ${selectedValue === 'CANCELLED' ? 'cancel' : 'complete'} this booking?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, confirm!',
                cancelButtonText: 'No, cancel',
            }).then((result) => {
                if (result.isConfirmed) {
                    // If confirmed, proceed with API call
                    const requestBody = {
                        bookingId: booking._id,
                        flag: selectedValue,
                    };

                    this.backendService.updateBooking(requestBody).subscribe({
                        next: (data) => {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: `Booking has been ${selectedValue.toLowerCase()} successfully!`,
                                showConfirmButton: false,
                                timer: 1500,
                            });
                            this.bookings = data.data;
                            this.rerender();
                            this.previousFlag = null; // Reset previous flag after success
                        },
                        error: (error) => {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'error',
                                title: error.error.message || 'An error occurred.',
                                showConfirmButton: false,
                            });
                        },
                    });
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    // If cancelled, revert the dropdown to the previous value
                    booking.flag = this.previousFlag;  // Reset to previous flag
                    this.previousFlag = null; // Reset previous flag after cancel
                    Swal.fire({
                        position: 'top-end',
                        icon: 'info',
                        title: 'Action cancelled!',
                        showConfirmButton: false,
                        timer: 1000,
                    });
                }
            });
        }
    }


    onPaymentStatusChange(selectedValue: string, item: any) {
        const requestBody = {
            bookingId: item._id,
            paymentStatus: selectedValue,
        };
        this.backendService.updateBooking(requestBody).subscribe({
            next: (data) => {
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: data.message,
                    showConfirmButton: false,
                    timer: 1500,
                });
                this.bookings = data.data;
                this.rerender();
            },
            error: (error) => {
                // Error callback
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: error.error.message,
                    showConfirmButton: false,
                    timer: 2500,
                });
            },
        });
    }

    ngOnInit() {
        this.today = this.data.getViewDate().subscribe((value) => (this.viewDate = value));
        this.previousFlag = null

        this.dropdownList = [
            {item_id: 1, item_text: "Mumbai"},
            {item_id: 2, item_text: "Bangaluru"},
            {item_id: 3, item_text: "Pune"},
            {item_id: 4, item_text: "Navsari"},
            {item_id: 5, item_text: "New Delhi"},
        ];
        this.selectedItems = [
            {item_id: 3, item_text: "Pune"},
            {item_id: 4, item_text: "Navsari"},
        ];
        this.dropdownSettings = {
            singleSelection: true,
            idField: "_id",
            textField: "displayValue",
            selectAllText: "Select All",
            unSelectAllText: "UnSelect All",
            itemsShowLimit: 3,
            allowSearchFilter: true,
        };

        this.currentUserRole = localStorage.getItem("role").toLowerCase();
        console.log(this.currentUserRole)
        console.log(this.today)
        this.getEmployees();
        this.startDate = DateUtils.createNgbDate(this.viewDate);
        this.endDate = DateUtils.createNgbDate(this.viewDate);

        this.formattedStartDate = this.utils.getStartDate(this.startDate);
        this.formattedEndDate = this.utils.getEndDate(this.endDate);

        const that = this;
        this.dtOptions = {
            pagingType: "full_numbers",
            pageLength: 100,
            serverSide: true,
            searching: true,
            autoWidth: false,
            processing: false,
            order: [0, "desc"],
            ajax: (dataTablesParameters: any, callback) => {
                that.http
                    .post<any>(
                        CONFIG.getClientsBookings,
                        Object.assign(dataTablesParameters, {
                            startDate: this.formattedStartDate,
                            endDate: this.formattedEndDate,
                        })
                    )
                    .subscribe((resp) => {
                        let data = resp.data.original;
                        this.dataTableParam = dataTablesParameters;
                        if (resp.data) {
                            that.bookings = data;
                            this.totals = resp.data.total;
                        }

                        callback({
                            recordsTotal: resp.data.recordsTotal,
                            recordsFiltered: resp.data.recordsFiltered,
                            data: [],
                        });
                    });
            },
            columns: [
                {data: "bookingName", orderable: true},
                {data: "client"},
                {data: "hours", orderable: true},
                {data: "startTime", orderable: true},
                {data: "endTime"},
                {data: "assignee"},
                {data: "subscription"},
                {data: "material"},
                {data: "materialCharges"},
                {data: "ratePerHour", orderable: true},
                {data: "paymentStatus"},
                {data: "description"},
            ],
        };

        this.rerender();

        // Handle Subscription API logic on form value changes
        this.form.get("isOneTime").valueChanges.subscribe((value: string) => {
            if (value === "false") {
                this.getClientSubscriptions();
            } else {
                this.currentClientSubscriptions = [];
                this.form.patchValue({
                    ratePerHour: null,
                    subscriptionId: null,
                });
                this.selectedSubscription = null;
            }
        });

        this.form.get("clientId").valueChanges.subscribe(() => {
            if (this.form.get("isOneTime").value === "false") {
                this.getClientSubscriptions();
            }
        });
    }

    viewDateChange(event: any) {
        this.viewDate = event;
        this.data.setViewDate(this.viewDate);

        this.startDate = DateUtils.createNgbDate(this.viewDate);
        this.endDate = DateUtils.createNgbDate(this.viewDate);

        this.onDateChange();

        this.rerender();
    }

    openModal({title, event = null}) {
        this.form.patchValue({
            bookingName: "Cleaning Booking",
            isOneTime: "true",
            subscriptionId: null,
            paymentStatus: "DUE",
            description: "XYZ",
            material: "false",
            materialCharges: null,
            ratePerHour: null,
            startDate: this.startDate,
        });

        this.modalTitle = title;

        // Fetch categories when the modal is opened
        // this.data.getAllCategories().subscribe((data) => {
        //     this.categories = data;
        // });

        // Fetch clients when the modal is opened
        this.data.getAllClients().subscribe((data) => {
            this.clients = data;
            this.clients = this.clients.filter((data) => {
                return (data.displayValue = data.name + "<->" + data.area);
            });
        });
        // Fetch employees list when the modal is opened

        // Wait for paymentStatus condition to run and render the element
        // If event object is provided i.e. booking already exists => to update an existing booking
        setTimeout(() => {
            if (event) {
                this.form.patchValue({
                    bookingName: event.title,
                    isOneTime: event.subscription ? "false" : "true",
                    clientId: event.client._id,
                    assigneeId: event.meta.user._id,
                    startTime: DateUtils.formatter.format(event.start),
                    endTime: DateUtils.formatter.format(event.end),
                    material: event.material ? "true" : "false",
                    materialCharges: event.materialCharges,
                    ratePerHour: event.ratePerHour,
                    paymentStatus: event.paymentStatus,
                    description: event.description,
                    phoneNumber: event.client.phoneNumber,
                });

                const currentAssigneeId = this.form.get("assigneeId").value;
                const currentAssigneeName = this.users.find(
                    (user) => user._id === currentAssigneeId
                ).name;

                this.assignees = [
                    {
                        _id: currentAssigneeId,
                        name: currentAssigneeName,
                    },
                ];

                if (this.form.get("isOneTime").value === "false") {
                    this.getClientSubscriptions(event.subscription);
                }

                this.startDate = DateUtils.createNgbDate(event.start);
            }
        });

        this.modalService
            .open(this.content, {ariaLabelledBy: "modal-basic-title"})
            .result.then(
            (result) => {
                // Handle close/dismiss
            },
            (reason) => {
                // Handle close/dismiss
            }
        );
    }

    toggleViews() {
        this.router.navigate(["/booking"]);
    }

    rerender(): void {
        if (this.dataTableElement && this.dataTableElement.dtInstance) {
            this.dataTableElement.dtInstance.then(
                (dtInstance: DataTables.Api) => {
                    dtInstance.draw();
                }
            );
        }
    }

    onItemSelect(item: any) {
        if (item) {
            this.form.patchValue({
                clientId: item._id,
            });
        } else {
            this.form.patchValue({
                clientId: null,
            });
        }

    }

    onItemDeSelect(item: any) {
        if (this.selectedClients.length <= 0) {
            this.form.patchValue({
                isOneTime: "true",
            });
        }
        console.log(this.form.value);
    }

    onSelectAll(items: any) {
        console.log(items);
    }

    getEmployees() {
        this.data.getEmployeeList().subscribe((employees) => {
            this.users = employees;
            this.users?.forEach((user, index) => (user.id = index));
        });
    }

    // Get client subscriptions
    getClientSubscriptions(subscriptionId?: string) {
        const clientId = this.form.get("clientId").value;

        const categoryId = this.users.find(
            (user) => user?._id === this.assignees[0]?._id
        )?.category._id;

        // No need to make api call if required parameters are not available
        if (!clientId || !categoryId) return;

        this.backendService
            .getClientSubscriptionsByCategory({clientId, categoryId})
            .subscribe({
                next: (response) => {
                    // Check if response contains data and whether the data is an array since data can be of any type
                    if (response.data && Array.isArray(response.data)) {
                        this.currentClientSubscriptions = response.data;

                        // Set initial subscription
                        if (this.currentClientSubscriptions.length > 0) {
                            // If subscriptionId is provided i.e. one wants to select a specific subscription by default
                            if (subscriptionId) {
                                const subscription = response.data.find(
                                    (sub: any) =>
                                        sub.subscription?._id === subscriptionId
                                );

                                // Since selectedSubscription and form control subscriptionId are two separate entities they are set individually
                                this.selectedSubscription = subscription?._id;
                                this.form.patchValue({
                                    ratePerHour: subscription?.ratePerHour,
                                    subscriptionId: subscription?._id,
                                });
                            } else {
                                // Else set the first most subscription
                                this.selectedSubscription =
                                    response.data[0]?._id;

                                // Same as above
                                this.form.patchValue({
                                    ratePerHour:
                                    response.data[0]?.subscription
                                        .ratePerHour,
                                    subscriptionId: response.data[0]?._id,
                                });
                            }
                        }
                    } else {
                        // If no subscriptions are found reset the associative values
                        this.selectedSubscription = null;
                        this.currentClientSubscriptions = [];
                        setTimeout(() => {
                            this.form.patchValue({isOneTime: "true"});
                        }, 1500);
                    }
                },
                error: (error) => {
                    console.log(error);
                },
            });
    }

    // Fill in the rate per hour if the subscription is changed
    selectSubscription(subscriptionId: string) {
        const clientSubscription = this.currentClientSubscriptions.find(
            (item) => item._id === subscriptionId
        );

        this.form.patchValue({
            ratePerHour: clientSubscription.subscription?.ratePerHour,
            subscriptionId,
        });
    }

    closeModal() {
        this.modalService.dismissAll(); // This will close all open modals
    }

    // Create booking data object
    createBookingReqBody() {
        const ngbDate: NgbDate = this.form.get("startDate").value;
        const startTime: string[] = this.form.get("startTime").value.split(":");
        const endTime: string[] = this.form.get("endTime").value.split(":");

        const formattedStartTime = this.utils.getFormattedDateTime(
            ngbDate,
            Number(startTime[0]),
            Number(startTime[1])
        );

        const formattedEndTime = this.utils.getFormattedDateTime(
            ngbDate,
            Number(endTime[0]),
            Number(endTime[1])
        );

        const bookingData = {
            ...this.form.value,
            startTime: formattedStartTime,
            endTime: formattedEndTime,
            assigneeId: this.assignees.map((assignee) => assignee._id),
        };

        delete bookingData.startDate;

        return bookingData;
    }

    // Handle multiple assignees
    addAssignee() {
        const employee = this.users.find(
            (user) => user._id === this.form.get("assigneeId").value
        );

        if (this.assignees.find((assignee) => assignee._id === employee._id)) {
            return;
        }
        this.assignees.push({_id: employee._id, name: employee.name});
    }

    removeAssignee(id: string) {
        this.assignees = this.assignees.filter((item) => item._id !== id);
    }

    addBooking() {
        this.bookingLoading = true;
        const bookingData = this.createBookingReqBody();

        this.backendService.addBooking(bookingData).subscribe({
            next: (data) => {
                this.form.reset();
                this.modalService.dismissAll(); // This will close all open modals
                this.rerender();
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: data.message,
                    showConfirmButton: false,
                    timer: 1500,
                });
                this.bookingLoading = false;
            },
            error: (error) => {
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: error.error.message,
                    text: error.error.message || "An error occurred.",
                    showConfirmButton: false,
                });
                this.bookingLoading = false;
            },
        });
    }

    extractHour(time: string): number {
        return parseInt(time.split(":")[0]);
    }

    extractMinute(time: string): number {
        return parseInt(time.split(":")[1]);
    }

    onDateChange() {
        // Handle date change logic here
        const {year, month, day} = this.startDate;

        this.data.setViewDate(new Date(year, month - 1, day));

        this.formattedStartDate = this.utils.getStartDate(this.startDate);
        this.formattedEndDate = this.utils.getEndDate(this.endDate);
    }

    handleCustomEvent(eventData: any) {
        // Handle the custom event data here
        console.log("Custom event data:", eventData);
        // You can perform any action here based on the eventData
    }

    // Open Modal For Client
    openClientDataModal(booking) {
        this.selectedBooking = booking
        this.clientData = booking;
        this.modalService
            .open(this.clientContent, {ariaLabelledBy: "modal-basic-title"})
            .result.then(
            (result) => {
                // Handle close/dismiss
            },
            (reason) => {
                // Handle close/dismiss
            }
        );
    }

    openEmployeeDataModal() {
        this.modalService
            .open(this.employeeContent, {ariaLabelledBy: "modal-basic-title"})
            .result.then(
            (result) => {
                // Handle close/dismiss
            },
            (reason) => {
                // Handle close/dismiss
            }
        );
    }

    sendBookingConfirmation(clientData) {
        console.log(clientData);

        const formattedDate = this.datePipe.transform(clientData.startTime, "medium");

        const message = `🌟 *Booking Confirmation* 🌟

Hi ${clientData?.client?.name},

Your booking for *${clientData.hours}* Hours ${clientData.material === true ? '*with material*' : '*without material*'}🧹🧴
on *${formattedDate}*⏰ has been successfully confirmed! ✅
Total amount you have to pay is *${clientData.totalAmount}* AED 💵
Your assigned cleaner: *${clientData?.assignee?.name}* 👩‍🔧✨

📝 *Note:*
* Your cleaner may arrive 20-30 minutes late due to unforeseen circumstances. 🚶
* If you wish to reschedule or cancel the booking, please notify us at least 24 hours before the scheduled time. ⏰🔄

Thank you for trusting *Home Brigadier*! We look forward to providing you with excellent service. 🏡❤️`;

        // Encode the message with encodeURIComponent
        const encodedMessage = encodeURIComponent(message);

        const whatsappUrl = `https://api.whatsapp.com/send/?phone=${clientData?.client?.phoneNumber}&text=${encodedMessage}`;
        window.open(whatsappUrl, "_blank");
    }

    sendBookingFeedback(clientData) {
        console.log(clientData);

        const message = `🌟 *Thank you!* 🌟

Your feedback helps us improve! Please share your experience by reviewing us on Google. 🌟

[Click here to review]👉 (https://tinyurl.com/HomeBrigadier)`;

        // Encode the message with encodeURIComponent
        const encodedMessage = encodeURIComponent(message);

        const whatsappUrl = `https://api.whatsapp.com/send/?phone=${clientData?.client?.phoneNumber}&text=${encodedMessage}`;
        window.open(whatsappUrl, "_blank");
    }


    generatePdf() {
        const doc = new jsPDF() as any;

        const ngbDate = DateUtils.createNgbDate(this.viewDate);

        const options = {
            startDate: this.formattedStartDate,
            endDate: this.formattedEndDate,
        };

        this.backendService.getAllBookings(options).subscribe({
            next: (response) => {
                const extractedData = response.data.map(
                    ({
                         assignee,
                         client,
                         description,
                         hours,
                         startTime,
                         endTime,
                         material,
                         totalAmount,
                         paymentStatus,
                     }) => ({
                        Assignee: assignee.name,
                        "Client Address": `${client.name} > ${client.address} > ${client.area}`,
                        Description: description,
                        Hours: hours,
                        StartTime: this.formatDateToTime(startTime),
                        EndTime: this.formatDateToTime(endTime),
                        Material: material ? "Yes" : "No",
                        "Payment Status": paymentStatus,
                        "T.Amount": totalAmount,
                    })
                );
                doc.setFontSize(20);
                doc.setTextColor(113, 32, 218);
                doc.text("HB Bookings", 15, 15);

                doc.setTextColor(0, 0, 0);
                const subtitle = `Start Date: ${this.formatDate(
                    this.formattedStartDate
                )} | End Date: ${this.formatDate(
                    this.formattedEndDate
                )} | Total Hours: ${
                    response?.total?.todayBookingHours
                } | Amount: ${response?.total?.todayBookingAmount}`;
                doc.setFontSize(12);
                doc.text(subtitle, 15, 25);
                console.log("extractedData", extractedData);
                doc.autoTable({
                    head: [
                        [
                            "Assignee",
                            "Client Address",
                            "Description",
                            "Hours",
                            "StartTime",
                            "EndTime",
                            "Material",
                            "Payment Status",
                            "T.Amount",
                        ],
                    ],
                    body: extractedData.map(Object.values),
                    startY: 35,
                    headStyles: {
                        fillColor: [113, 32, 218],
                    },
                });

                doc.save(
                    `Bookings-from-${this.formatDate(
                        this.formattedStartDate
                    )}-To-${this.formatDate(this.formattedEndDate)}.pdf`
                );
            },
            error: (error) => {
                console.log(error);
            },
        });
    }

    formatDate(dateTimeString: string): string {
        const date = new Date(dateTimeString);
        return this.datePipe.transform(date, "dd-MM-yy") || "";
    }

    formatDateToTime(dateTimeString: string): string {
        const date = new Date(dateTimeString);
        return this.datePipe.transform(date, "hh:mm a") || "";
    }
}

class PDF extends jsPDF {
    autoTable: any;
}
