import { Injectable } from '@angular/core';
import {Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private alertSubject = new Subject<any>();

  constructor() { }

  getAlerts(): Observable<any> {
    return this.alertSubject.asObservable();
  }

  success(message: string) {
    this.alertSubject.next({ type: 'success', message });
  }

  error(message: string) {
    this.alertSubject.next({ type: 'error', message });
  }

  // clear() {
  //   this.alertSubject.next();
  // }
}
