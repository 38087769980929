import {Component, OnDestroy, OnInit} from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "../../service/auth.service";
import Swal from "sweetalert2";
import { CONFIG } from "config";
import { BackendService } from "src/app/shared/services/backend.service";

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit,OnDestroy {
    public loginForm: FormGroup;
    public show: boolean = false;
    public errorMessage: string;
    themeConfig: any = {};

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private authService: AuthService,
        private backendService:BackendService
    ) {
        this.loginForm = this.fb.group({
            email: ["", [Validators.required, Validators.email]],
            password: ["", Validators.required],
        });
    }
    ipInfo={
        "ip": "0",
        "city": "0",
        "region": "0",
        "country": "0",
        "loc": "0",
        "org": "0",
        "postal": "0",
        "timezone": "0"
    }
    interval: any;
    ngOnDestroy(): void {
        window.clearInterval(this.interval);
    }
    ngOnInit():void {
        this.themeConfig = JSON.parse(localStorage.getItem('themeConfig') as string);
        if (!this.themeConfig) {
            this.interval = window.setInterval(() => {
                this.themeConfig = JSON.parse(localStorage.getItem('themeConfig') as string);
                if (this.themeConfig) {
                    window.clearInterval(this.interval);
                }
            }, 50);
        }
    }
    getIpInfo(){
        this.authService.getIpLocation().subscribe((locRes: any) => {
            this.login(locRes)
          },
            (error) => {                              //Error callback
                this.login(this.ipInfo)
            });
    }
    login(ipInfo:any) {
        const { email, password } = this.loginForm.value;
        this.authService.login(email, password, ipInfo).subscribe({
            next: (response) => {
                const { userDetails } = response.data;
                const { name, role } = userDetails;
                localStorage.setItem("userName", name);
                localStorage.setItem("role", role);
                this.backendService.getThemeConfig({});
                if (
                    role.toLowerCase() !== "super" ||
                    role.toLowerCase() !== "admin" ||
                    role.toLowerCase() !== "manager"
                ) {
                    return this.router.navigate(["/booking"]);
                }
                this.router.navigate(["/simple-page/first-page"]);
            },
            error: (error) => {
                if (error.status === 401) {
                    this.logout();
                }
                // Error callback
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "Failed to login.",
                    showConfirmButton: false,
                });
            },
        });
    }

    logout() {
        this.authService.logout();
        this.router.navigateByUrl("auth/login");
    }

    showPassword() {
        this.show = !this.show;
    }
}
