<app-breadcrumb
    [items]="['Settings']"
    [title]="isInvoice ? 'Invoices' : 'Quotations'"
    [active_item]="isInvoice ? 'Invoices' : 'Quotations'">
</app-breadcrumb>

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div
                    class="p-2 d-flex flex-column flex-md-row gap-2 justify-content-start align-items-start justify-content-md-between align-items-md-center">
                    <div class="theme-form flex-column flex-md-row align-items-start align-items-md-stretch"
                         style="display: flex; gap: 10px">
                        <div class="input-group">
                            <div class="input-group-text">From</div>
                            <input #startDatePicker="ngbDatepicker" (click)="startDatePicker.toggle()"
                                   (ngModelChange)="onDateChange()"
                                   [(ngModel)]="startDate" [displayMonths]="displayMonths" [navigation]="navigation"
                                   [outsideDays]="outsideDays" [showWeekNumbers]="showWeekNumbers"
                                   class="datepicker-here form-control digits"
                                   name="startDate" ngbDatepicker
                                   placeholder="Start Date"/>
                        </div>
                        <div class="input-group">
                            <div class="input-group-text">To</div>
                            <input #endDatePicker="ngbDatepicker" (click)="endDatePicker.toggle()"
                                   (ngModelChange)="onDateChange()"
                                   [(ngModel)]="endDate" [displayMonths]="displayMonths" [navigation]="navigation"
                                   [outsideDays]="outsideDays" [showWeekNumbers]="showWeekNumbers"
                                   class="datepicker-here form-control digits"
                                   name="endDate" ngbDatepicker
                                   placeholder="End Date"/>
                        </div>
                        <div class="d-grid d-md-block w-100">
                            <button (click)="rerender()" class="btn btn-primary-gradien h-100">
                                Search
                            </button>
                        </div>
                    </div>
                    <button class="btn btn-primary" (click)="openModal(isInvoice ? modals.addModal : modals.addModal, true)">
                        Add {{ isInvoice ? 'Invoice' : 'Quotation' }}
                    </button>

                </div>

                <div class="card-body p-4">
                    <div class="col-12 account-statement-tbl">
                        <div class="table-responsive">
                            <table [dtOptions]="dtOptions" class="row-border hover" datatable>
                                <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Client</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Created By</th>
                                    <th class="actions text-end">
                                        Actions
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let quotation of quotations">
                                    <td>
                                        {{
                                            quotation.date
                                                | date : "MMM d, y, h:mm a"
                                        }}
                                    </td>
                                    <td>
                                        {{
                                            quotation.client?.name +
                                            " > " +
                                            quotation.client?.address +
                                            " > " +
                                            quotation.client?.area
                                        }}
                                    </td>
                                    <td>
                                        {{ quotation.totalAmount }}
                                    </td>
                                    <td [ngClass]="{
                                                'text-success':
                                                    quotation.status ===
                                                    'ACCEPTED',
                                                'text-info':
                                                    quotation.status ===
                                                    'PENDING',
                                                'text-danger':
                                                    quotation.status ===
                                                    'EXPIRED'
                                            }" class="fw-medium">
                                        {{ quotation.status }}
                                    </td>
                                    <td>
                                        {{ quotation.createdBy }}
                                    </td>
                                    <td class="actions d-flex gap-3 align-items-center justify-content-end">
                                        <app-feather-icons (click)="
                                                    editQuotation(quotation)
                                                " [icon]="'edit'" style="
                                                    color: #bbb;
                                                    cursor: pointer;
                                                "></app-feather-icons>

                                        <app-feather-icons (click)="
                                                    copyQuotation(quotation)
                                                " [icon]="'copy'" style="
                                                    color: #bbb;
                                                    cursor: pointer;
                                                "></app-feather-icons>

                                        <app-feather-icons (click)="
                                                    viewQuotation(quotation)
                                                " [icon]="'book-open'" style="
                                                    color: #bbb;
                                                    cursor: pointer;
                                                "></app-feather-icons>

                                        <app-feather-icons (click)="
                                                    sendQuotation(quotation)
                                                " [icon]="'share-2'" style="
                                                    color: #bbb;
                                                    cursor: pointer;
                                                "></app-feather-icons>

                                        <app-feather-icons (click)="
                                                    downloadQuotation(quotation)
                                                " [icon]="'download'" style="
                                                    color: #bbb;
                                                    cursor: pointer;
                                                "></app-feather-icons>

                                        <!-- <app-feather-icons [icon]="'more-horizontal'" style="
                                                color: #bbb;
                                                cursor: pointer;
                                            "></app-feather-icons> -->
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h3>{{ currentModal }}</h3>

        <button (click)="modal.dismiss('Cross click')" class="btn-close"></button>
    </div>

    <div class="modal-body py-4">
        <div class="mx-auto" style="max-width: 1000px">
            <form (ngSubmit)="onSubmit()" [formGroup]="quotationForm">
                <div class="row">
                    <div class="col-md-6 mb-1">
                        <div class="form-group">
                            <label for="quotationNumber">{{ isInvoice ? 'Invoice No' : 'Quotation No' }}</label>
                            <div class="input-group">
                                <!-- Input for Quotation/Invoice Number -->
                                <input
                                    class="form-control"
                                    formControlName="quotationNumber"
                                    id="quotationNumber"
                                    [disabled]="!isEditingNumber"
                                type="text"/>

                                <!-- "Change" Text: Clicking this will allow editing -->
                                <span class="input-group-text" (click)="toggleEditNumber()" style="cursor: pointer; color: blue;">
                {{ isEditingNumber ? 'Done' : 'Change' }}
            </span>
                            </div>

                            <!-- Validation message -->
                            <div *ngIf="
                quotationNumber?.invalid &&
                (quotationNumber?.dirty || quotationNumber?.touched)
            " class="text-danger">
                                <div *ngIf="quotationNumber?.errors?.required">
                                    {{ isInvoice ? 'Invoice' : 'Quotation' }} No is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-1">
                        <div class="form-group position-relative">
                            <label for="client">Client</label>

                            <ng-select [editableSearchTerm]="true" [items]="clients"
                                       [multiple]="true"
                                       bindLabel="displayValue"
                                       bindValue="_id"
                                       formControlName="client" placeholder="Select Clients"></ng-select>

                            <p *ngIf="selectedClients.length" class="position-absolute top-100 end-0"
                               style="color: var(--theme-deafult)">
                                {{ getClient(selectedClients[0]._id)?.area }}
                            </p>
                            <div *ngIf="
                                    customerName?.invalid &&
                                    (customerName?.dirty ||
                                        customerName?.touched)
                                " class="text-danger">
                                <div *ngIf="customerName?.errors?.required">
                                    Client Name is required.
                                </div>
                                <div *ngIf="customerName?.errors?.minlength">
                                    Client Name must be at least 3 characters
                                    long.
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Conditionally show NTN field when a client is selected -->
                    <div *ngIf="selectedClients.length" class="col-md-6 mb-1">
                        <div class="form-group">
                            <label for="ntnNumber">NTN Number</label>
                            <input class="form-control" formControlName="ntnNumber" id="ntnNumber" type="text"/>
                        </div>
                    </div>
                    <div class="col-md-6 mb-1">
                        <div class="form-group">
                            <label for="date">{{ isInvoice ? 'Invoice Date' : 'Quotation Date' }}</label>
                            <input #d="ngbDatepicker" (click)="d.toggle()"
                                   [displayMonths]="displayMonths" [navigation]="navigation" [outsideDays]="outsideDays"
                                   [showWeekNumbers]="showWeekNumbers" class="datepicker-here form-control digits"
                                   formControlName="date"
                                   name="date" ngbDatepicker/>
                            <div *ngIf="
                                    date?.invalid &&
                                    (date?.dirty || date?.touched)
                                " class="text-danger">
                                <div *ngIf="date?.errors?.required">
                                    Quotation Date is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-1">
                        <div class="form-group">
                            <label for="expiryDate">Expiration Date</label>
                            <input #d="ngbDatepicker" (click)="d.toggle()"
                                   [displayMonths]="displayMonths" [navigation]="navigation" [outsideDays]="outsideDays"
                                   [showWeekNumbers]="showWeekNumbers" class="datepicker-here form-control digits"
                                   formControlName="expiryDate"
                                   name="expiryDate" ngbDatepicker/>
                            <div *ngIf="
                                    expiryDate?.invalid &&
                                    (expiryDate?.dirty || expiryDate?.touched)
                                " class="text-danger">
                                <div *ngIf="expiryDate?.errors?.required">
                                    Quotation Date is required.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-6 col-lg-3 mb-1">
                        <div class="form-group">
                            <label for="vatPercentage">VAT Rate %</label>
                            <input class="form-control" formControlName="vatPercentage" id="vatPercentage"
                                   type="number"/>
                            <div *ngIf="
                                    vatPercentage?.invalid &&
                                    (vatPercentage?.dirty ||
                                        vatPercentage?.touched)
                                " class="text-danger">
                                <div *ngIf="vatPercentage?.errors?.required">
                                    VAT Rate is required.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-6 col-lg-3 mb-1">
                        <div class="form-group">
                            <label for="discount">Discount %</label>
                            <input class="form-control" formControlName="discount" id="discount" type="number"/>
                        </div>
                    </div>
                </div>

                <div formArrayName="items">
                    <div *ngFor="let product of items.controls; let i = index" [formGroupName]="i"
                         class="row position-relative justify-content-center">
                        <div class="col-12 row p-0">
                            <div class="col-md-6 col-lg-3 col-lg-2 mb-1">
                                <div class="form-group">
                                    <label for="title">Item Name</label>
                                    <input class="form-control" formControlName="title" id="title" type="text"/>
                                    <div *ngIf="
                                            product.get('title')?.invalid &&
                                            (product.get('title')?.dirty ||
                                                product.get('title')?.touched)
                                        " class="text-danger">
                                        <div *ngIf="
                                                product.get('title')?.errors
                                                    ?.required
                                            ">
                                            Item Name is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-3 mb-1">
                                <div class="form-group">
                                    <label for="description">Description</label>
                                    <input class="form-control" formControlName="description" id="description"
                                           type="text"/>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-2 mb-1">
                                <div class="form-group">
                                    <label for="rate">Rate</label>
                                    <input class="form-control" formControlName="rate" id="rate" type="number"/>
                                    <div *ngIf="
                                            product.get('rate')?.invalid &&
                                            (product.get('rate')?.dirty ||
                                                product.get('rate')?.touched)
                                        " class="text-danger">
                                        <div *ngIf="
                                                product.get('rate')?.errors
                                                    ?.required
                                            ">
                                            Rate is required.
                                        </div>
                                        <div *ngIf="
                                                product.get('rate')?.errors
                                                    ?.pattern
                                            ">
                                            Rate must be a valid number.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-2 mb-1">
                                <div class="form-group">
                                    <label for="quantity">Quantity</label>
                                    <input class="form-control" formControlName="quantity" id="quantity"
                                           type="number"/>
                                    <div *ngIf="
                                            product.get('quantity')?.invalid &&
                                            (product.get('quantity')?.dirty ||
                                                product.get('quantity')
                                                    ?.touched)
                                        " class="text-danger">
                                        <div *ngIf="
                                                product.get('quantity')?.errors
                                                    ?.required
                                            ">
                                            Quantity is required.
                                        </div>
                                        <div *ngIf="
                                                product.get('quantity')?.errors
                                                    ?.pattern
                                            ">
                                            Quantity must be a valid number.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-2 mb-1">
                                <div class="form-group">
                                    <label for="amount">Amount</label>
                                    <input [value]="
                                            product.get('quantity').value *
                                            product.get('rate').value
                                        " class="form-control" disabled id="amount" type="number"/>
                                </div>
                            </div>
                        </div>

                        <div class="position-absolute top-0 end-0 h-100 w-auto d-flex gap-1">
                            <app-feather-icons (click)="removeProduct(i)" *ngIf="items.controls?.length > 1"
                                               [icon]="'minus-circle'" class="text-danger"
                                               style="cursor: pointer"></app-feather-icons>

                            <app-feather-icons (click)="addProduct()" *ngIf="i === items.controls?.length - 1"
                                               [icon]="'plus-circle'" class="text-success"
                                               style="cursor: pointer"></app-feather-icons>
                        </div>
                    </div>

                    <!-- <button
                        type="button"
                        class="btn btn-success mb-3"
                        (click)="addProduct()"
                    >
                        Add Product
                    </button> -->
                </div>

                <div class="row">
                    <div class="col-md-3">
                        <div class="form-group">
                            <label class="form-label" for="status">Status</label>
                            <select class="form-select" formControlName="status" id="status" type="text">
                                <option value="PENDING">PENDING</option>
                                <option value="EXPIRED">EXPIRED</option>
                                <option value="CANCELLED">CANCELLED</option>
                                <option value="ACCEPTED">ACCEPTED</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-end my-4 my-md-0">
                    <div class="totals-card p-3 rounded-4 d-flex flex-column gap-2 col-md-6 col-lg-4">
                        <div class="d-flex align-items-center justify-content-between">
                            <h5>Amount</h5>
                            <h5>{{ totals.totalAmount | currency : "AED" }}</h5>
                        </div>
                        <div class="d-flex align-items-center justify-content-between">
                            <h5>VAT</h5>
                            <h5>{{ totals.totalVat | currency : "AED" }}</h5>
                        </div>
                        <div class="d-flex align-items-center justify-content-between">
                            <h5>Discount</h5>
                            <h5>
                                {{ totals.totalDiscount | currency : "AED" }}
                            </h5>
                        </div>
                        <div class="border-top"></div>
                        <div class="d-flex align-items-center justify-content-between"
                             style="color: var(--theme-deafult)">
                            <h4>Total: (AED)</h4>
                            <h4>
                                {{
                                    totals.totalAmount +
                                    totals.totalVat -
                                    totals.totalDiscount | currency : "AED"
                                }}
                            </h4>
                        </div>
                    </div>
                </div>

                <button [disabled]="quotationForm.invalid || requestProcessing"
                        class="btn btn-primary d-flex gap-2 align-items-center"
                        type="submit">
                    @if (requestProcessing) {
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span>Loading...</span>
                    } @else {
                        <span>{{ currentModal }}</span>
                    }
                </button>
            </form>
        </div>
    </div>
</ng-template>
<app-view-quotation [pdfDataUri]="pdfDataUri"></app-view-quotation>
