import {Component, OnInit, ViewChild} from "@angular/core";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";

import {NgbDate, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {UtilsService} from "src/app/shared/services/utils.service";
import {DataTableDirective} from "angular-datatables";
import {HttpClient} from "@angular/common/http";
import {CONFIG} from "config";
import {DateUtils} from "src/app/utils/date-utils";
import {DataSharingService} from "src/app/shared/services/data-sharing.service";
import {CurrencyPipe, DatePipe, DecimalPipe} from "@angular/common";
import {BackendService} from "src/app/shared/services/backend.service";
import Swal from "sweetalert2";
import {v4 as uuidv4} from "uuid";
import {jsPDF} from "jspdf";
import "jspdf-autotable";
import { Router } from "@angular/router";

@Component({
    selector: "app-quotation",
    templateUrl: "./quotation.component.html",
    styleUrl: "./quotation.component.scss",
})
export class QuotationComponent implements OnInit {
    @ViewChild("content") content: any;

    @ViewChild(DataTableDirective, {static: false})
    dataTableElement!: DataTableDirective;
    dtOptions: DataTables.Settings = {};

    dataTableParam: any;

    startDate: NgbDate;
    endDate: NgbDate;
    formattedStartDate: any;
    formattedEndDate: any;

    displayMonths = 1;
    navigation = "select";
    outsideDays = "visible";
    showWeekNumbers = true;

    quotations: any[] = [];
    quotationForm: FormGroup;

    // For clients
    clients: any[] = [];
    selectedClients: any[] = [];
    dropdownSettings = {};

    totals = {
        totalAmount: 0,
        totalVat: 0,
        totalDiscount: 0,
    };

    requestProcessing: boolean = false;

    // Modals
    modals = {
        editModal: "Update Quotation",
        addModal: "Add Quotation",
    };
    currentModal: string;
    currentQuotation: string;

    // For pdf
    pdfDataUri: any;
    isInvoice: boolean = false;

    constructor(
        private fb: FormBuilder,
        private utils: UtilsService,
        private http: HttpClient,
        private modalService: NgbModal,
        private data: DataSharingService,
        private decimal: DecimalPipe,
        private backend: BackendService,
        private date: DatePipe,
        private currency: CurrencyPipe,
        private router: Router
    ) {
        this.quotationForm = this.fb.group({
            quotationNumber: [
                { value: this.isInvoice ? 'INV-' + this.generateNumber() : 'QUO-' + this.generateNumber(), disabled: true },
                Validators.required
            ],
            client: ["", [Validators.required]],
            date: [DateUtils.createNgbDate(new Date()), Validators.required],
            expiryDate: [
                DateUtils.createNgbDate(new Date()),
                Validators.required,
            ],
            vatPercentage: [0, Validators.required],
            discount: [0, Validators.required],
            items: this.fb.array([this.createProduct()]),
            status: ["PENDING", Validators.required],
        });
    }

    ngOnInit(): void {
        this.isInvoice = this.router.url.includes("invoice");
        const currentDate = new Date();
        this.startDate = DateUtils.createNgbDate(currentDate);
        this.endDate = DateUtils.createNgbDate(currentDate);

        this.formattedStartDate = this.utils.getStartDate(this.startDate);
        this.formattedEndDate = this.utils.getEndDate(this.endDate);

        this.updateFormattedDates();

        const that = this;

        this.dtOptions = {
            pagingType: "full_numbers",
            pageLength: 10,
            serverSide: true,
            searching: false,
            autoWidth: false,
            processing: false,
            order: [0, "desc"],
            ajax: (dataTablesParameters: any, callback) => {
                that.http
                    .post<any>(
                        CONFIG.getAllQuotations,
                        Object.assign(dataTablesParameters, {
                            startDate: this.formattedStartDate,
                            endDate: this.formattedEndDate,
                        })
                    )
                    .subscribe((resp) => {
                        let data = resp.data.original;
                        this.dataTableParam = dataTablesParameters;
                        if (resp.data) {
                            that.quotations = data;
                        }

                        callback({
                            recordsTotal: resp.data.recordsTotal,
                            recordsFiltered: resp.data.recordsFiltered,
                            data: [],
                        });
                    });
            },
            columns: [
                {data: "date", orderable: true},
                {data: "client", orderable: true},
                {data: "totalAmount", orderable: true},
                {data: "status", orderable: true},
                {data: "createdBy", orderable: true},
            ],
        };

        this.rerender();



        this.calculateTotals(this.quotationForm.value);

        this.quotationForm.valueChanges.subscribe((value: any[]) => {
            this.calculateTotals(value);
        });
    }
    toggleEditNumber() {
        this.isEditingNumber = !this.isEditingNumber;

        // Enable or disable the input based on the toggle state
        if (this.isEditingNumber) {
            this.quotationForm.get('quotationNumber')?.enable();
        } else {
            this.quotationForm.get('quotationNumber')?.disable();
        }
    }

    // Helper method to generate a random number for invoice/quotation
    generateNumber(): string {
        return Math.floor(1000 + Math.random() * 9000).toString();
    }

    get quotationNo() {
        return this.quotationForm.get("quotationNo");
    }

    get quotationDate() {
        return this.quotationForm.get("quotationDate");
    }

    get customerName() {
        return this.quotationForm.get("customerName");
    }

    get serviceDescription() {
        return this.quotationForm.get("serviceDescription");
    }

    get price() {
        return this.quotationForm.get("price");
    }

    get vatPercentage() {
        return this.quotationForm.get("vatPercentage");
    }

    get discount() {
        return this.quotationForm.get("discount");
    }

    get items() {
        return this.quotationForm.get("items") as FormArray;
    }



    rerender(): void {
        if (this.dataTableElement && this.dataTableElement.dtInstance) {
            this.dataTableElement.dtInstance.then(
                (dtInstance: DataTables.Api) => {
                    dtInstance.draw();
                }
            );
        }
    }

    calculateTotals(value: any) {
        this.resetTotals();

        value.items.forEach(({rate, quantity}: any) => {
            this.totals.totalAmount += rate * quantity;
            this.totals.totalDiscount +=
                ((rate * quantity) / 100) * value.discount;
            this.totals.totalVat +=
                ((rate * quantity) / 100) * value.vatPercentage;
        });

        this.totals.totalAmount = this.normalizeValue(this.totals.totalAmount);
        this.totals.totalDiscount = this.normalizeValue(
            this.totals.totalDiscount
        );
        this.totals.totalVat = this.normalizeValue(this.totals.totalVat);
    }

    resetTotals() {
        this.totals = {
            totalAmount: 0,
            totalVat: 0,
            totalDiscount: 0,
        };
    }

    normalizeValue(value: any) {
        return Number(this.decimal.transform(value, "1.0-2").replace(",", ""));
    }

    // For multi select input
    onItemSelect(item: any) {
        this.quotationForm.patchValue({
            client: item._id,
        });
    }

    onItemDeSelect(item: any) {
        if (this.selectedClients.length <= 0) {
            this.quotationForm.patchValue({
                client: null,
            });
        }
    }

    onSelectAll(items: any) {
        console.log(items);
    }

    getClient(clientId: string) {
        return this.clients.find((client) => client._id === clientId);
    }

    updateFormattedDates() {
        this.formattedStartDate = this.utils.getStartDate(this.startDate);
        this.formattedEndDate = this.utils.getEndDate(this.endDate);
    }

    onDateChange() {
        this.updateFormattedDates();
    }


    openModal(modal: string, reset?: boolean) {
        if (reset) {
            this.resetForm();
        }

        this.currentModal = modal;

        this.data.getAllClients().subscribe((value) => {
            this.clients = value.map((data: any) => ({
                _id: data._id,
                displayValue: `${data.name} <-> ${data.area}`
            }));

        });
        this.modalService.open(this.content, {size: "xl"});
    }

    resetForm() {
        this.quotationForm.patchValue({
            quotationNumber: this.isInvoice ? 'INV-' + uuidv4() : uuidv4(),
            client: "",
            date: DateUtils.createNgbDate(new Date()),
            expiryDate: DateUtils.createNgbDate(new Date()),
            vatPercentage: 0,
            discount: 0,
            status: "PENDING",
        });
        this.quotationForm.setControl(
            "items",
            this.fb.array([this.createProduct()])
        );
    }

    closeModal() {
        this.modalService.dismissAll();
    }

    // Quotations
    isEditingNumber: boolean = false;
    editQuotation(quotation: any) {
        this.currentQuotation = quotation._id;
        this.backend.getQuotationById({id: quotation._id}).subscribe({
            next: (response) => {
                const {
                    quotationNumber,
                    client: {_id: clientId},
                    date,
                    expiryDate,
                    vatPercentage,
                    totalDiscount,
                    totalAmount,
                    items,
                    status,
                } = response.data;

                const discount = this.normalizeValue(
                    (totalDiscount / totalAmount) * 100
                );

                const client = this.clients.find(
                    (client) => client._id === clientId
                );

                this.selectedClients = [
                    {
                        displayValue: client?.displayValue,
                        _id: client?._id,
                    },
                ];

                this.quotationForm.patchValue({
                    quotationNumber,
                    client: client?._id,
                    date: DateUtils.createNgbDate(new Date(date)),
                    expiryDate: DateUtils.createNgbDate(new Date(expiryDate)),
                    vatPercentage,
                    discount,
                    status,
                });

                this.items.clear();
                items.forEach((item: any) => {
                    this.addProduct(item);
                });

                this.openModal(this.modals.editModal);
            },
            error: (error) => {
                console.log(error);
            },
        });
    }

    copyQuotation(quotation: any) {
        console.log(quotation);
    }

    sendQuotation(quotation: any) {
        this.generatePdf(quotation).then((doc: jsPDF) => {
            // Convert the PDF to a Blob
            const pdfBlob = doc.output("blob");

            // Create a Blob URL for the PDF
            const fileURL = URL.createObjectURL(pdfBlob);

            // Check if the Web Share API is supported
            if (navigator.share) {
                navigator
                    .share({
                        title: "Quotation",
                        text: "Please find the attached quotation.",
                        files: [
                            new File(
                                [pdfBlob],
                                `${quotation.client?.name}-quotation.pdf`,
                                {type: pdfBlob.type}
                            ),
                        ],
                    })
                    .then(() => {
                        console.log("Share was successful.");
                    })
                    .catch((error) => {
                        console.error("Sharing failed", error);
                    });
            } else {
                console.error(
                    "Web Share API is not supported in this browser."
                );
                // WhatsApp Web URL with a pre-filled message and file URL
                const whatsappURL = `https://wa.me/${
                    this.clients.find(
                        (client) => client._id === quotation.client?._id
                    )?.phoneNumber
                }?text=Please find the attached quotation&url=${fileURL}`;

                // Open the WhatsApp URL
                window.open(whatsappURL, "_blank");
                // Fallback code to handle sharing not supported case
            }
        });
    }

    downloadQuotation(quotation: any) {
        this.generatePdf(quotation).then((doc: jsPDF) => {
            doc.save(`${quotation.client?.name}-quotation.pdf`);
        });
    }

    viewQuotation(quotation: any) {
        this.generatePdf(quotation).then((doc: jsPDF) => {
            this.pdfDataUri = doc.output("bloburl");
        });
    }

    async generatePdf(quotation: any): Promise<jsPDF> {
        return new Promise((resolve, reject) => {
            const doc = new jsPDF() as any;

            // Get the default paper size dimensions
            const width = doc.internal.pageSize.getWidth();
            const height = doc.internal.pageSize.getHeight();

            doc.addImage(
                "/assets/images/quotation/Letter.jpeg",
                "JPEG",
                0,
                0,
                width,
                height
            );

            doc.setTextColor(80, 80, 80);
            doc.setFontSize(25);
            doc.text('Quotation', 12, 46);
            doc.setFontSize(10);
            doc.text(`To: ${quotation.client?.name}`, 75, 45);
            doc.text(
                `Date: ${this.date.transform(quotation.date, "d MMMM yyyy")}`,
                75,
                55
            );

            this.backend.getQuotationById({id: quotation._id}).subscribe({
                next: (response) => {
                    const data = response.data;
                    const extractedData = data.items.map(
                        ({description, quantity, rate}) => ({
                            Description: description,
                            Quantity: quantity,
                            "Unit Price": this.currency.transform(rate, "AED"),
                            Cost: this.currency.transform(
                                quantity * rate,
                                "AED"
                            ),
                        })
                    );

                    doc.autoTable({
                        head: [
                            ["Description", "Quantity", "Unit Price", "Cost"],
                        ],
                        body: extractedData.map(Object.values),
                        margin: {left: 75},
                        startY: 80,
                        headStyles: {
                            fillColor: [113, 32, 218],
                        },
                        tableWidth: 120,
                    });

                    // Add a horizontal line at the last row
                    const lastRowY = doc.lastAutoTable.finalY;
                    doc.setDrawColor(80, 80, 80);
                    doc.setLineWidth(0.5);
                    doc.line(75, lastRowY + 5, 195, lastRowY + 5);

                    doc.text("Total Amount:", 133, lastRowY + 15);
                    doc.text(
                        this.currency.transform(data.totalAmount, "AED"),
                        165,
                        lastRowY + 15
                    );

                    doc.text("Vat:", 133, lastRowY + 22);
                    doc.text(
                        this.currency.transform(data.totalVat, "AED"),
                        165,
                        lastRowY + 22
                    );

                    doc.text("Discount:", 133, lastRowY + 29);
                    doc.text(
                        this.currency.transform(data.totalDiscount, "AED"),
                        165,
                        lastRowY + 29
                    );

                    doc.setLineWidth(0.5);
                    doc.line(75, lastRowY + 34, 195, lastRowY + 34);

                    doc.text("Net Amount:", 133, lastRowY + 40);
                    doc.text(
                        this.currency.transform(
                            data.totalAmount +
                            data.totalVat -
                            data.totalDiscount,
                            "AED"
                        ),
                        165,
                        lastRowY + 40
                    );

                    doc.text(
                        "* You need to Give 60% advance and 40% after completion of the work",
                        75,
                        lastRowY + 55
                    );
                    doc.text("Thank you For your Business", 75, lastRowY + 67);
                    doc.text("Sincerely yours,", 75, lastRowY + 75);
                    doc.text("M. Usama Khan,", 75, lastRowY + 80);
                    resolve(doc);
                },
                error: (error) => {
                    console.log(error);
                    reject(error);
                },
            });
        });
    }

    createProduct(product?: any): FormGroup {
        return this.fb.group({
            title: [product?.title ?? "", Validators.required],
            description: [product?.description ?? ""],
            rate: [
                product?.rate ?? 1,
                [
                    Validators.required,
                    Validators.pattern(/^[0-9]+(\.[0-9]{1,2})?$/),
                ],
            ],
            quantity: [
                product?.quantity ?? 1,
                [Validators.required, Validators.pattern(/^[0-9]+$/)],
            ],
        });
    }

    addProduct(product?: any): void {
        this.items.push(this.createProduct(product));
    }

    removeProduct(index: number): void {
        this.items.removeAt(index);
    }

    onSubmit() {
        if (this.quotationForm.valid) {
            this.requestProcessing = true;

            // Common data for both Quotation and Invoice
            const reqBody = {
                ...this.totals,
                ...this.quotationForm.getRawValue(),
                quotationNumber: this.isInvoice
                    ? this.quotationForm.get('quotationNumber')?.value.replace('INV-', '')
                    : this.quotationForm.get('quotationNumber')?.value,
                items: this.quotationForm.value.items.map((item: any) => {
                    return {
                        ...item,
                        discount: this.normalizeValue(
                            (item.rate / 100) * this.discount.value
                        ),
                        vat: this.normalizeValue(
                            (item.rate / 100) * this.vatPercentage.value
                        ),
                    };
                }),
                date: this.utils.getStartDate(this.quotationForm.value.date),
                expiryDate: this.utils.getEndDate(
                    this.quotationForm.value.expiryDate
                ),
            };

            delete reqBody.discount; // Remove discount if not needed

            // Construct the final payload
            const payload = {
                type: this.isInvoice ? 'invoice' : 'quotation',
                ...reqBody
            };

            // Handle request processing based on current modal state
            if (this.currentModal === this.modals.addModal) {
                this.backend.addQuotation(payload).subscribe({
                    next: (response) => {
                        Swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: `${this.isInvoice ? 'Invoice' : 'Quotation'} added successfully`,
                            timer: 2000,
                            showConfirmButton: false,
                        });
                        this.closeModal();
                        this.requestProcessing = false;
                        this.rerender();
                    },
                    error: (error) => {
                        Swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: 'Error adding data',
                            showConfirmButton: false,
                        });
                        this.requestProcessing = false;
                    },
                });
            } else {
                this.backend.updateQuotation({ id: this.currentQuotation, ...payload }).subscribe({
                    next: (response) => {
                        Swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: `${this.isInvoice ? 'Invoice' : 'Quotation'} updated successfully`,
                            timer: 2000,
                            showConfirmButton: false,
                        });
                        this.closeModal();
                        this.requestProcessing = false;
                        this.rerender();
                    },
                    error: (error) => {
                        Swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: 'Error updating data',
                            showConfirmButton: false,
                        });
                        this.requestProcessing = false;
                    },
                });
            }
        } else {
            console.log("Quotation form is invalid");
        }
    }


}
