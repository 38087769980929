<app-breadcrumb
    [items]="['Settings']"
    [title]="'Clients'"
    active_item="Clients"
></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="p-2">
                    <button
                        (click)="openModal()"
                        class="btn btn-primary"
                        style="float: right"
                    >
                        Add Clients
                    </button>
                </div>
                <div class="card-body p-4">
                    <div class="col-12 account-statement-tbl">
                        <div class="table-responsive">
                            <table
                                [dtOptions]="dtOptions"
                                class="row-border hover"
                                datatable
                            >
                                <thead>
                                <tr>
                                    <th>Id</th>
                                    <th class="suspense">Name</th>
                                    <th>Area</th>
                                    <th>Phone</th>
                                    <th>Email</th>
                                    <th>Flat/Address</th>
                                    <th>Customer Type</th>
                                    <th>Balance</th>
                                    <th class="actions">Action</th>
                                </tr>
                                </thead>
                                <tbody *ngIf="persons?.length != 0">
                                <tr
                                    *ngFor="
                                            let person of users;
                                            let i = index
                                        "
                                >
                                    <td>{{ i + 1 }}</td>
                                    <td class="suspense">
                                        {{ person?.name }}
                                    </td>
                                    <td>{{ person?.area }}</td>
                                    <td
                                        (mouseenter)="inCellView = true"
                                        (mouseleave)="
                                                inCellView = false;
                                                hideTooltip()
                                            "
                                    >
                                            <span
                                                (mouseenter)="showTooltip(i)"
                                                NgbTooltip="Chat on WhatsApp"
                                            >{{ person?.phoneNumber }}</span
                                            >
                                        <div
                                            (click)="
                                                    chatOnWhatsapp(
                                                        person?.phoneNumber
                                                    )
                                                "
                                            (mouseenter)="
                                                    inTooltipView = true
                                                "
                                            (mouseleave)="
                                                    inTooltipView = false;
                                                    hideTooltip()
                                                "
                                            *ngIf="activeTooltipIndex === i"
                                            NgbTooltipContent
                                            class="position-absolute bottom-100 d-flex gap-2 align-items-center justify-content-center bg-white p-2 rounded-4 shadow"
                                            style="cursor: pointer"
                                        >
                                            <i
                                                class="bi bi-whatsapp text-success fs-5"
                                            ></i>
                                            Chat on WhatsApp
                                        </div>
                                    </td>
                                    <td>{{ person?.email }}</td>
                                    <td>{{ person?.address }}</td>
                                    <td>{{ person?.customerType }}</td>
                                    <td
                                        [class]="
                                                person?.walletBallance > 0
                                                    ? 'text-success'
                                                    : 'text-danger'
                                            "
                                    >
                                        {{ person?.walletBallance }}
                                    </td>
                                    <td
                                        class="d-flex gap-1 actions"
                                        style="overflow: unset"
                                    >
                                        <button
                                            (click)="
                                                    openAccountHistoryModal(
                                                        person?._id,
                                                        person?.name
                                                    )
                                                "
                                            class="btn btn-pill btn-primary-gradien"
                                            data-bs-original-title=""
                                            title=""
                                            type="button"
                                        >
                                            Account History
                                        </button>
                                        <button
                                            (click)="
                                                    openBookingHistoryModal(
                                                        person?._id,
                                                        person?.name
                                                    )
                                                "
                                            class="btn btn-pill btn-warning-gradien"
                                            data-bs-original-title=""
                                            title=""
                                            type="button"
                                        >
                                            Booking History
                                        </button>
                                        <!-- <button
                                            class="btn btn-pill btn-warning-gradien"
                                            type="button"
                                            data-bs-original-title=""
                                            title=""
                                            (click)="
                                                openSubscriptionModal(
                                                    person?._id
                                                )
                                            "
                                        >
                                            Add Subscription
                                        </button>
                                        <button
                                            class="btn btn-pill btn-secondary-gradien"
                                            type="button"
                                            data-bs-original-title=""
                                            title=""
                                            (click)="
                                                openShowSubscriptionsModal(
                                                    person
                                                )
                                            "
                                        >
                                            View Subscriptions
                                        </button> -->
                                        <button
                                            (click)="openDeposit(person)"
                                            class="btn btn-pill btn-info-gradien"
                                            data-bs-original-title=""
                                            title=""
                                            type="button"
                                        >
                                            Deposit/Withdraw
                                        </button>
                                        <!-- <button
                                            class="btn btn-pill btn-primary-gradien"
                                            type="button"
                                            data-bs-original-title=""
                                            title=""
                                            (click)="
                                                openEditClientModal(person)
                                            "
                                        >
                                            Edit
                                        </button>
                                        <button
                                            class="btn btn-pill btn-danger btn-air-danger btn-danger-gradien"
                                            type="button"
                                            data-bs-original-title=""
                                            title=""
                                        >
                                            Delete
                                        </button> -->
                                        <div class="position-relative">
                                            <i
                                                (click)="toggleDropdown(i)"
                                                class="bi bi-three-dots-vertical h5"
                                                style="
                                                        cursor: pointer;
                                                        line-height: 2;
                                                    "
                                            ></i>

                                            @if (i === moreActive) {
                                                <ul
                                                    class="profile-dropdown position-absolute"
                                                    [ngStyle]="dropdownStyle"
                                                >
                                                    <li
                                                        class="d-flex align-items-center"
                                                    >
                                                        <app-feather-icons
                                                            [icon]="'map-pin'"
                                                            size="14px"
                                                            style="
                                                                margin-right: 4px;
                                                            "
                                                        ></app-feather-icons>
                                                        <span
                                                        >Pin Location : {{person?.pinLocation}}</span
                                                        >
                                                    </li>
                                                    <li
                                                        class="d-flex align-items-center"
                                                        (click)="
                                                            openSubscriptionModal(
                                                                person?._id
                                                            )
                                                        "
                                                    >
                                                        <app-feather-icons
                                                            [icon]="'plus'"
                                                            style="
                                                                margin-right: 4px;
                                                            "
                                                        ></app-feather-icons>
                                                        <span
                                                        >Add
                                                            Subscription</span
                                                        >
                                                    </li>
                                                    <li
                                                        class="d-flex align-items-center"
                                                        (click)="
                                                            openShowSubscriptionsModal(
                                                                person
                                                            )
                                                        "
                                                    >
                                                        <app-feather-icons
                                                            style="
                                                                margin-right: 4px;
                                                            "
                                                            [icon]="'eye'"
                                                        ></app-feather-icons>
                                                        <span
                                                        >View Subscription
                                                        </span>
                                                    </li>
                                                    <li
                                                        class="d-flex align-items-center"
                                                        (click)="
                                                            openEditClientModal(
                                                                person
                                                            )
                                                        "
                                                    >
                                                        <app-feather-icons
                                                            style="
                                                                margin-right: 4px;
                                                            "
                                                            [icon]="'edit'"
                                                        ></app-feather-icons>
                                                        <span>Edit</span>
                                                    </li>
                                                    <li
                                                        class="d-flex align-items-center"
                                                    >
                                                        <app-feather-icons
                                                            style="
                                                                margin-right: 4px;
                                                            "
                                                            [icon]="'trash'"
                                                        ></app-feather-icons>
                                                        <span>Delete</span>
                                                    </li>
                                                </ul>
                                            }
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                                <tbody *ngIf="users?.length == 0">
                                <tr>
                                    <td
                                        [colSpan]="
                                                dtOptions?.columns.length + 2
                                            "
                                        class="no-data-available text-center"
                                    >
                                        No data!
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <ul class="profile-dropdown" [ngStyle]="dropdownStyle">
    <li>
        <a href="javascript:void(0)">
            <app-feather-icons [icon]="'settings'"></app-feather-icons>
            <span>Settings</span>
        </a>
    </li>
    <li class="d-flex">
        <app-feather-icons [icon]="'edit'"></app-feather-icons>
        <span>Change Password</span>
    </li>
    <li>
        <app-feather-icons [icon]="'log-in'"></app-feather-icons>
        <span>Log out</span>
    </li>
</ul> -->

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{ modal_title }}</h4>
        <button
            (click)="modal.dismiss('Cross click')"
            aria-label="Close"
            class="btn-close"
            type="button"
        ></button>
    </div>
    <div class="modal-body">
        <form (ngSubmit)="onSubmit()" [formGroup]="customerForm">
            <div class="row">
                <div class="col-md-6 col-sm-12 mb-3">
                    <label class="form-label" for="name">Name</label>
                    <input
                        class="form-control"
                        formControlName="name"
                        id="name"
                        placeholder="Enter Name"
                        type="text"
                    />
                    <div
                        *ngIf="
                        customerForm.get('name').invalid &&
                        customerForm.get('name').touched
                    "
                        class="text-danger"
                    >
                        Name is required
                    </div>
                </div>

                <div class="col-md-6 col-sm-12 mb-3">
                    <label class="form-label" for="email">Email</label>
                    <input
                        class="form-control"
                        formControlName="email"
                        id="email"
                        placeholder="Enter Email"
                        type="email"
                    />
                    <div
                        *ngIf="
                        customerForm.get('email').invalid &&
                        customerForm.get('email').touched
                    "
                        class="text-danger"
                    >
                        Valid email is required
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12 mb-3">
                    <label class="form-label" for="phoneNumber">Phone Number</label>
                    <input
                        class="form-control"
                        formControlName="phoneNumber"
                        id="phoneNumber"
                        placeholder="Enter Phone Number"
                        type="tel"
                    />
                    <div
                        *ngIf="
                        customerForm.get('phoneNumber').invalid &&
                        customerForm.get('phoneNumber').touched
                    "
                        class="text-danger"
                    >
                        Valid phone number is required (e.g., +1234567890)
                    </div>
                </div>

                <div class="col-md-6 col-sm-12 mb-3">
                    <label class="form-label" for="address">Address</label>
                    <input
                        class="form-control"
                        formControlName="address"
                        id="address"
                        placeholder="Enter Address"
                        type="text"
                    />
                    <div
                        *ngIf="
                        customerForm.get('address').invalid &&
                        customerForm.get('address').touched
                    "
                        class="text-danger"
                    >
                        Address is required
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12 mb-3">
                    <label class="form-label" for="area">Area</label>
                    <input
                        class="form-control"
                        formControlName="area"
                        id="area"
                        placeholder="Enter Area"
                        type="text"
                    />
                    <div
                        *ngIf="
                        customerForm.get('area').invalid &&
                        customerForm.get('area').touched
                    "
                        class="text-danger"
                    >
                        Area is required
                    </div>
                </div>

                <div class="col-md-6 col-sm-12 mb-3">
                    <label class="form-label" for="customerType"
                    >Customer Type</label
                    >
                    <input
                        class="form-control"
                        formControlName="customerType"
                        id="customerType"
                        placeholder="Enter Customer Type"
                        type="text"
                    />
                    <div
                        *ngIf="
                        customerForm.get('customerType').invalid &&
                        customerForm.get('customerType').touched
                    "
                        class="text-danger"
                    >
                        Customer Type is required
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12 mb-3">
                    <label class="form-label" for="pinLocation">PIN Location</label>
                    <input
                        class="form-control"
                        formControlName="pinLocation"
                        id="pinLocation"
                        placeholder="Enter PIN Location"
                        type="text"
                    />
                    <div
                        *ngIf="
                        customerForm.get('pinLocation').invalid &&
                        customerForm.get('pinLocation').touched
                    "
                        class="text-danger"
                    >
                        PIN Location is required
                    </div>
                </div>
                <div class="col-md-6 col-sm-12 mb-3">
                    <label class="form-label" for="ntnNumber">NTN Number</label>
                    <input
                        class="form-control"
                        formControlName="ntnNumber"
                        id="ntnNumber"
                        placeholder="Enter Ntn Number"
                        type="number"
                    />
                    <div
                        *ngIf="
                        customerForm.get('ntnNumber').invalid &&
                        customerForm.get('ntnNumber').touched
                    "
                        class="text-danger"
                    >
                        NTN Number is required
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-sm-12 mb-3">
                <label class="form-label" for="emiratesID">Emirates ID</label>
                <input
                    class="form-control"
                    formControlName="emiratesID"
                    id="emiratesID"
                    placeholder="Enter Emirates ID"
                    type="text"
                />
                <div
                    *ngIf="
                        customerForm.get('emiratesID').invalid &&
                        customerForm.get('emiratesID').touched
                    "
                    class="text-danger"
                >
                    Valid Emirates ID is required (15 digits)
                </div>
            </div>

            <button
                [disabled]="customerForm.invalid || requestProcessing"
                class="btn btn-primary d-flex gap-2 align-items-center"
                type="submit"
            >
                @if (requestProcessing) {
                    <span
                        class="spinner-border spinner-border-sm"
                        aria-hidden="true"
                    ></span>
                    <span role="status">Loading...</span>
                } @else {
                    <span>{{ modal_button }}</span>
                }
            </button>
        </form>
    </div>
</ng-template>

<ng-template #subscriptionContent let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            Add Client Subscription
        </h4>
        <button
            (click)="modal.dismiss('Cross click')"
            aria-label="Close"
            class="btn-close"
            type="button"
        ></button>
    </div>
    <div class="modal-body">
        <form
            (ngSubmit)="onSubmitSubscription()"
            [formGroup]="clientSubscriptionForm"
        >
            <div class="mb-3">
                <label for="clientId">Select Category:</label><br/>
                <select
                    (change)="onCategoryChange()"
                    class="form-control"
                    formControlName="categoryId"
                    id="clientId"
                    style="appearance: auto"
                >
                    <option
                        *ngFor="let category of categories"
                        [value]="category._id"
                    >
                        {{ category.categoryName }}
                    </option>
                    <!-- Add more options as needed -->
                </select>
                <div
                    *ngIf="
                        clientSubscriptionForm.get('categoryId').invalid &&
                        clientSubscriptionForm.get('categoryId').touched
                    "
                    class="text-danger"
                >
                    Please select a category.
                </div>
            </div>
            <div class="row align-items-end mb-3">
                <div class="col-7">
                    <label for="subscriptionId">Select Subscription:</label
                    ><br/>
                    <select
                        (change)="getSubscriptionDetails()"
                        class="form-control"
                        formControlName="subscriptionId"
                        id="subscriptionId"
                        style="appearance: auto"
                    >
                        <option
                            *ngFor="let subscription of subscriptions"
                            [value]="subscription._id"
                        >
                            {{ subscription.subscriptionName }}
                        </option>
                        <!-- Add more options as needed -->
                    </select>

                    <div
                        *ngIf="
                            clientSubscriptionForm.get('subscriptionId')
                                .invalid &&
                            clientSubscriptionForm.get('subscriptionId').touched
                        "
                        class="text-danger"
                    >
                        Please select a subscription.
                    </div>
                </div>
                <div class="col-4">
                    <button
                        (click)="addCustomSubscription()"
                        class="btn btn-warning btn-outline-warning"
                        type="button"
                    >
                        Custom Subscription
                    </button>
                </div>
            </div>
            <button
                [disabled]="clientSubscriptionForm.invalid || requestProcessing"
                class="btn btn-primary d-flex gap-2 align-items-center"
                type="submit"
            >
                @if (requestProcessing) {
                    <span
                        class="spinner-border spinner-border-sm"
                        aria-hidden="true"
                    ></span>
                    <span role="status">Loading...</span>
                } @else {
                    <span>Submit</span>
                }
            </button>
        </form>
    </div>
</ng-template>

<ng-template #showSubscriptionsContent let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            Active Subscriptions:-
            <span class="text-success">{{ currentClient?.name }}</span>
        </h4>
        <button
            (click)="modal.dismiss('Cross click')"
            aria-label="Close"
            class="btn-close"
            type="button"
        ></button>
    </div>
    <div class="modal-body">
        <div class="table-responsive">
            <table class="table table-striped table-hover">
                <thead>
                <tr>
                    <th scope="col">Id</th>
                    <th scope="col">Subscription Name</th>
                    <th scope="col">Category</th>
                    <th scope="col">Hours</th>
                    <th scope="col">Visits</th>
                    <th scope="col">Rate Per Hour</th>
                    <th scope="col">Price</th>
                    <th scope="col">Count</th>
                    <th scope="col">Status</th>
                </tr>
                </thead>
                <tbody *ngIf="currentClientSubscriptions?.length != 0">
                <tr
                    *ngFor="
                            let clientSubscription of currentClientSubscriptions;
                            let i = index
                        "
                >
                    <td scope="row">{{ i + 1 }}</td>
                    <td>
                        {{
                            clientSubscription?.subscription
                                ?.subscriptionName
                        }}
                    </td>
                    <td>
                        {{
                            clientSubscription?.subscription?.category
                                ?.categoryName
                        }}
                    </td>
                    <td
                        [ngClass]="{
                                'text-danger':
                                    clientSubscription?.remainingHours <= 0
                            }"
                    >
                        {{ clientSubscription?.remainingHours }}
                        /
                        {{ clientSubscription?.subscription?.totalHours }}
                    </td>
                    <td
                        [ngClass]="{
                                'text-danger':
                                    clientSubscription?.remainingVisits <= 0
                            }"
                    >
                        {{ clientSubscription?.remainingVisits }}
                        /
                        {{ clientSubscription?.subscription?.totalVisits }}
                    </td>
                    <td>
                        {{ clientSubscription?.subscription?.ratePerHour }}
                    </td>
                    <td>
                        {{ clientSubscription?.subscription?.price }}
                    </td>
                    <td>
                        {{ clientSubscription?.count }}
                    </td>
                    <td>
                        <select
                            (ngModelChange)="
                                    onSubscriptionStatusChange(
                                        clientSubscription?.client,
                                        clientSubscription?._id,
                                        $event
                                    )
                                "
                            [(ngModel)]="clientSubscription.status"
                            class="form-select"
                            id="status"
                            name="status"
                        >
                            <option value="COMPLETED">COMPLETED</option>
                            <option value="SUSPENDED">SUSPENDED</option>
                            <option value="ACTIVE">ACTIVE</option>
                        </select>
                    </td>
                </tr>
                </tbody>
                <tbody *ngIf="currentClientSubscriptions?.length == 0">
                <tr>
                    <td class="no-data-available text-center" colspan="9">
                        No Active Subscriptions!
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-template>

<ng-template #accountHistoryContent let-modal>
    <app-client-account-history></app-client-account-history>
</ng-template>

<ng-template #bookingHistoryContent let-modal>
    <app-client-booking-history></app-client-booking-history>
</ng-template>

<ng-template #depositContent let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Deposit - Withdraw</h4>
        <button
            (click)="modal.dismiss('Cross click')"
            aria-label="Close"
            class="btn-close"
            type="button"
        ></button>
    </div>
    <div class="modal-body">
        <div
            class="client-info mb-1 d-flex justify-content-between align-items-center"
        >
            <div>
                <span class="d-inline-block me-2 px-2 py-1 bg-success rounded"
                >User</span
                >
                <span class="fw-bold">
                    {{ currentClient?.name }}
                </span>
            </div>
            <div>
                <span>Client Bal: </span>
                <span class="fw-bold">
                    {{ currentClient?.walletBallance }}
                </span>
            </div>
        </div>

        <form [formGroup]="transactionForm">
            <div class="mb-3">
                <label class="form-label" for="amount">Amount</label>
                <input
                    (click)="selectAmount($event)"
                    class="form-control"
                    formControlName="amount"
                    id="amount"
                    placeholder="Enter amount"
                    type="number"
                />
                <div
                    *ngIf="
                        transactionForm.get('amount').errors?.required &&
                        transactionForm.get('amount').touched
                    "
                    class="text-danger"
                >
                    Amount is required
                </div>
                <div
                    *ngIf="
                        transactionForm.get('amount').errors?.min &&
                        transactionForm.get('amount').touched
                    "
                    class="text-danger"
                >
                    Amount should be more than 0
                </div>
            </div>

            <div class="mb-3">
                <label class="form-label" for="remarks">Remarks</label>
                <input
                    class="form-control"
                    formControlName="remarks"
                    id="remarks"
                    placeholder="Enter remarks"
                    type="text"
                />
            </div>

            <div class="modal-footer d-flex gap-2">
                <button
                    (click)="onSubmitDw('D')"
                    [disabled]="transactionForm.invalid || requestProcessing"
                    class="btn btn-success d-flex gap-2 align-items-center"
                    type="button"
                >
                    @if (requestProcessing) {
                        <span
                            class="spinner-border spinner-border-sm"
                            aria-hidden="true"
                        ></span>
                        <span role="status">Loading...</span>
                    } @else {
                        <span>Deposit</span>
                    }
                </button>
                <button
                    (click)="onSubmitDw('W')"
                    [disabled]="transactionForm.invalid || requestProcessing"
                    class="btn btn-danger d-flex gap-2 align-items-center"
                    type="button"
                >
                    @if (requestProcessing) {
                        <span
                            class="spinner-border spinner-border-sm"
                            aria-hidden="true"
                        ></span>
                        <span role="status">Loading...</span>
                    } @else {
                        <span>Withdraw</span>
                    }
                </button>
            </div>
        </form>
    </div>
</ng-template>
